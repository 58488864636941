<template>
  <main class="usage-policy">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h1 class="font-bold underlined">
            {{ $t("heading.terms") }}
          </h1>
        </b-col>
        <b-col cols="12" class="mt-3">
          <p
            class="font-light"
            style="font-size: 1.3rem; color: #060e49"
            v-html="terms"
          ></p>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UsagePolicy",
  data() {
    return {
      terms: ""
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.terms = this.settings("terms_and_conditions");
      },
      immediate: true
    }
  }
};
</script>
